
//
window.documentPanel = () => {
  return {
    openPanel: false,
    title: '',
    href: '',
    previewable: false,
    openDocumentPanel(el) {
      this.title        = el.getAttribute('title');
      this.href         = el.getAttribute('href');
      this.previewable  = el.getAttribute('data-previewable') === 'true';
      this.openPanel = true;
    },
  };
};
