
$(() => {

  $(document).on('click', '.select-search li', function() {
    const $li         = $(this);
    const $container  = $li.parents('.select-search');
    const $input      = $container.find('input[type="hidden"]');
    const $showInput  = $container.find('input[type="text"]');

    $input.val($li.data('value'));
    $input.trigger('change');
    $showInput.val($showInput.prop('readonly',true));
    $showInput.val($li.text());
    $container.find('ul').hide();
    $container.find('.clear').show();
    $container.find('.list').hide();
  });

  $(document).on('click', '.select-search .clear', function() {
    const $container  = $(this).parents('.select-search');
    const $input      = $container.find('input[type="hidden"]');
    const $showInput  = $container.find('input[type="text"]');

    $input.val('');
    $input.trigger('change');
    $showInput.val('');
    $showInput.prop('readonly',false);
    $(this).hide();
    $container.find('.list').show();
  });

  $(document).on('keyup', '.select-search input[type="text"]', function() {
    const $container  = $(this).parents('.select-search');
    const $ul         = $container.find('ul');
    const url = $container.data('url');
    if ($(this).val().length < 3) {
      $ul.hide();
      return ;
    }

    $.get(url + '?search=' + $(this).val(), (data) => {
      $ul.empty();
      if (data.results) {
        $ul.show();
        for (const result of data.results) {
          const $li = $('<li>', { 'data-value': result.id, text: result.name});
          $li.addClass('relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-gray-100');
          $ul.append($li);
        }
      }
    });
  });
});
