const modalName = 'defaultMessagesModal';

Alpine.data(modalName, () => {
  return {
    isModalOpen: false,
    modalContent: '',
    openModal(el) {
      const href = el.getAttribute('href');
      fetch(href)
      .then(response => {
        if (response.ok) {
          this.isModalOpen = true;
          this.modalContent = response.text();
        }
      });
    },
  };
});
