
$(() => {
  if ($('select[name="role"]').length < 1) {
    return ;
  }

  const rolesWithUT = [ 'agent', 'respadm' ];

  $('select[name="role"]').on('change', function() {
    if (rolesWithUT.includes($(this).val())) {
      $('.delegation_select').show();
    } else {
      $('.delegation_select').hide();
    }

    if ($(this).val() === 'agent') {
      $('.max_folders').show();
    } else {
      $('.max_folders').hide();
    }
  });
  $('select[name="role"]').trigger('change');
});
