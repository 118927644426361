


$(() => {


  const chat = $('#messages-chat');
  if (!chat.length) {
    return;
  }

  // scroll to bottom
  const scrollDown = () => {
    const list = $('#messages-list');
    let h = 0;
    list.children().each(function() {
      h += $(this).outerHeight();
    });
    list[0].scrollTo(0, h);
  };
  scrollDown();

  // refres messages (AJAX)
  const refresh = () => {
    $.get(chat.data('url'), function(html) {
      $('#messages-list').html(html);
      scrollDown();
    });
  };

  // refresh every 10s
  setInterval(refresh, 10000);
  
  $('#messages-form').on('submit', function(e) {
    e.preventDefault();
    const textarea = $('textarea[name=message]');
    const message = textarea.val();
    if (!message) {
      return;
    }
    $.post($(this).attr('action'), { message }, function() {
      textarea.val('');
      refresh();
    });
  });

});