
// css
require('../scss/main.scss');


// jquery
require('./auto-toggle');
require('./select-search');
require('./notifications');
require('./clickable-row');
require('./zipcodes');
require('./autocomplete');
require('./select-foldertype');
require('./auto-submit');
require('./messages-chat');
require('./document-upload');
require('./tooltips');
require('./refresh');
require('./agent/users');
require('./agent/tables');
require('./agent/folders');
require('./agent/blocks');

require('./global/applicant');
require('./global/timezone');

// alpine.js
require('./document-panel');
require('./alpine-modal');


$(() => {
  // delete confirm
  $(document).on('click', '[data-confirm]', function() {
    const message = $(this).data('confirm');
    return window.confirm(message);
  });

  // tom-select
  $('select.tom-select').each(function() {
    const el = $(this);
    const create    = el.data('option-creation');
    const multiple  = el.prop('multiple');
    new window.TomSelect(el.get(0), {
      hidePlaceholder: true,
      create,
      plugins: multiple ? ['remove_button',] : [],
      onInitialize: function() {
        this.wrapper.classList.remove('hidden');
      },
      render:{
        option_create: (data, escape) => {
          return '<div class="create">Ajouter <strong>' + escape(data.input) + '</strong>&hellip;</div>';
        },
        no_results: () => {
          return '<div class="no-results">Aucun résultat trouvé</div>';
        },
      }
    });
  });

});