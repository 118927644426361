
$(() => {
  // zipcodes and cities
  const refreshCities = function() {
    const target = $($(this).data('target'));
    $.get('https://geo.api.gouv.fr/communes?codePostal=' + $(this).val(), function(data) {
      target.find('option[value!=\'\']').remove();
      if (data) {
        for (const city of data) {
          target.append($('<option>', { value: city.nom, text: city.nom }));
        }
        if (data.length === 1) {
          target.val(data[0].nom);
        } else if (target.data('value') && data.find(c => c.nom === target.data('value'))) {
          target.val(target.data('value'));
        } else {
          target.val('');
        }
      }
    });
  };
  $(document).on('change', 'input.zipcode', refreshCities);
  $(document).on('keyup', 'input.zipcode', refreshCities);

  window.initRefreshCities = (container) => {
    $('input.zipcode', container).each(refreshCities);
  };

  window.initRefreshCities('body');
});