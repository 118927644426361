

window.blockHandler = (el) => {
  return {
    result: '',
    fetchData(url) {
      fetch(url)
      .then(response => response.text())
      .then(data => {
        this.result = data;
      }).then(() => {
        window.initAutoToggle(el);
        window.initRefreshCities(el);
      });
    },
    submitForm(url) {
      const form = el.querySelector('form');
      const formData = new FormData(form);

      fetch(url, {
        method: 'POST',
        body: formData
      })
      .then(response => response.text())
      .then(data => {
        this.result = data;
      }).then(() => {
        window.initAutoToggle(el);
        window.initRefreshCities(el);
      });
    }
  };
};
