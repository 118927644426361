
//
window.timezoneDate = (isoDate) => {
  return new Intl.DateTimeFormat('fr-FR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }).format(new Date(isoDate));
};

window.timeZones = () => {
  return {
    time: {
      guadeloupe: '',
      martinique: '',
      guyane: '',
      laReunion: '',
      mayotte: '',
      hexagone: ''
    },
    init() {
      this.updateTimes();
      setInterval(this.updateTimes.bind(this), 1000);
    },
    updateTimes() {
      const now = new Date();
      const options = { hour: '2-digit', minute: '2-digit', hourCycle: 'h24' };
      this.time.guadeloupe = now.toLocaleString('fr-FR', { ...options, timeZone: 'America/Guadeloupe' });
      this.time.martinique = now.toLocaleString('fr-FR', { ...options, timeZone: 'America/Martinique' });
      this.time.guyane = now.toLocaleString('fr-FR', { ...options, timeZone: 'America/Cayenne' });
      this.time.laReunion = now.toLocaleString('fr-FR', { ...options, timeZone: 'Indian/Reunion' });
      this.time.mayotte = now.toLocaleString('fr-FR', { ...options, timeZone: 'Indian/Mayotte' });
      this.time.hexagone = now.toLocaleString('fr-FR', { ...options, timeZone: 'Europe/Paris' });
    }
  };

};
