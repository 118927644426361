$(() => {
  
  // sources
  const SOURCES = {
    communes: {
      url: 'https://geo.api.gouv.fr/communes?limit=15&nom=',
      transform: data => {
        data.forEach(d => {
          d.codePostal = d.codesPostaux[0];
        });
        return data;
      },
      selected: result => {
        $('input[name="birth_city"]').val(result.nom);
        $('input[name="birth_zipcode"]').val(result.codePostal);
      }
    },
    regions: {
      url: 'https://geo.api.gouv.fr/regions?limit=15&nom=',
      transform: data => {
        return data;
      },
      selected: (result, prefix, ul) => {
        $(ul).parent().find('input').val(result.nom);
      }
    },
    adresses: {
      url: 'https://api-adresse.data.gouv.fr/search/?limit=15&q=',
      transform: data => {
        return data.features.map(f => f.properties);
      },
      selected: (result, prefix) => {
        $(`input[name=${prefix}address1]`).val(result.name);
        $(`input[name=${prefix}zipcode]`).val(result.postcode);
        $(`select[name=${prefix}city]`).data('value', result.city);
        $(`input[name=${prefix}zipcode]`).trigger('change');
      }
    },
    airports_stations: {
      stations_url: 'https://ressources.data.sncf.com/api/explore/v2.1/catalog/datasets/referentiel-gares-voyageurs/records?limit=10&lang=fr&where=',
      airports_url : 'https://api.api-ninjas.com/v1/airports?name=',
      transform: data => {
        return data.results;
      },
      selected: (result, prefix, ul) => {
        $(ul).parent().find('input').val(result.alias_libelle_noncontraint);
      }
    }
  };

  // autocomplete
  const autocomplete = function() {
    var q   = $(this).val();
    if (q.length < 4) {
      return;
    }
    const $input = $(this);
    const source = SOURCES[$input.data('src')];

    const ul = $($input.parent().find('ul.autocomplete-list'));
    const li = ul.find('li.hidden');
    
    if (source.paramWithQuote) {
      q = '\''+q+'\'';
    }
    $.get(source.url + encodeURIComponent(q), function(data) {
      data = source.transform(data);
      if (!data || data.length === 0) {
        ul.addClass('hidden');
        return;
      }
      ul.find('li:not(.hidden)').remove();
      ul.removeClass('hidden');
      for (const result of data) {
        const item = li.clone();
        item.removeClass('hidden');
        item.data('result', result);
        item.data('source', source);
        Object.keys(result).forEach(key => {
          item.find(`[data-attr="${key}"]`).text(result[key]);
        });
        ul.append(item);
      }
    });
  };

  // autocomplete
  const autocompleteAirportAndStations = function() {
    var q   = $(this).val();
    if (q.length < 4) {
      return;
    }
    const $input = $(this);
    const source = SOURCES[$input.data('src')];

    const ul = $($input.parent().find('ul.autocomplete-list'));
    const li = ul.find('li.hidden');
    
    // Appel AJAX pour les gares
    $.get(source.stations_url + encodeURIComponent('\''+q+'\''), function(data1) {
      data1 = source.transform(data1);
      //Appel Ajax pour les aéroports

      $.get({
        url: source.airports_url + encodeURIComponent(q), 
        headers: {
          'X-Api-Key': 'X+n3kWjLJBxtgSxA29haWA==gCi2te5EPsDW1lEX' //Free API key limited to 10000 requests
        },
        success: function(data2) {
          data2.forEach(result => {
            if (Object.prototype.hasOwnProperty.call(result, 'name')) {
              result.alias_libelle_noncontraint = result.name;
              result.commune_libellemin = result.city;
              delete result.name;
              delete result.city;
            }
          });
          // Fusion des résultats des deux sources de données
          const mergedData = data2.concat(data1);
          if (!mergedData || mergedData.length === 0) {
            ul.addClass('hidden');
            return;
          }
          ul.find('li:not(.hidden)').remove();
          ul.removeClass('hidden');
          for (const result of mergedData) {
            const item = li.clone();
            item.removeClass('hidden');
            item.data('result', result);
            item.data('source', source);
            Object.keys(result).forEach(key => {
              item.find(`[data-attr="${key}"]`).text(result[key]);
            });
            ul.append(item);
          }
        },
      });
    });
  };

  const dismiss = function() {
    setTimeout(() => {
      const ul = $($(this).parent().find('ul.autocomplete-list'));
      ul.addClass('hidden');
    }, 300);
  };

  $(document).on('keyup', 'input.autocomplete', autocomplete);
  $(document).on('focus', 'input.autocomplete', autocomplete);
  $(document).on('blur',  'input.autocomplete', dismiss);
  $(document).on('click', 'button.btn-autocomplete', function() { autocomplete.apply($(this).parent().find('input.autocomplete').trigger('focus')); });

  $(document).on('keyup', 'input.autocompleteAirportAndStations', autocompleteAirportAndStations);
  $(document).on('focus', 'input.autocompleteAirportAndStations', autocompleteAirportAndStations);
  $(document).on('blur',  'input.autocompleteAirportAndStations', dismiss);
  $(document).on('click', 'button.btn-autocompleteAirportAndStations', function() { autocompleteAirportAndStations.apply($(this).parent().find('input.autocompleteAirportAndStations').trigger('focus')); });

  $(document).on('click', 'ul.autocomplete-list > li', function() {
    const ul      = $(this).parent();
    const prefix  = ul.data('prefix') || '';

    const source  = $(this).data('source');
    const result  = $(this).data('result');

    source.selected(result, prefix, ul);
  });

});